<template>
  <div v-if="isActive" />
</template>

<script>
import { iubenda } from '@/lib/features'
import iubendaMixin from './mixin'
import IubendaCookiePurposes from '@/lib/enums/IubendaCookiePurposes'

export default {
  mixins: [iubendaMixin],

  data: () => ({
    isActive: iubenda?.isActive,
    config: iubenda?.config
  }),

  mounted () {
    if (this.isActive) {
      this.init()
    }
  },

  methods: {
    init () {
      this.loadConsentSolution()

      ;[
        `https://cs.iubenda.com/autoblocking/${this.config.siteId}.js`,
        '//cdn.iubenda.com/cs/gpp/stub.js',
        '//cdn.iubenda.com/cs/iubenda_cs.js'
      ].forEach((src) => {
        this.loadScript({ src })
      })
    },

    loadConsentSolution () {
      const lang = this.locale
      const siteId = this.config.siteId
      const cookiePolicyId = this.config.documentId[lang]
      const consentSolutionConfig = {
        ...this.config.consentSolutionConfig,
        cookiePolicyId,
        siteId,
        lang,
        banner: {
          // Logo can be overridden via config
          logo: require('@/../public/img/logo-large.png'),
          ...this.config.consentSolutionConfig.banner
        },
        callback: {
          onPreferenceExpressedOrNotNeeded: this.onPreferenceExpressedOrNotNeededCallback
        }
      }

      window._iub = window._iub || []
      window._iub.csConfiguration = consentSolutionConfig
    },

    loadScript ({ src, scriptContent }) {
      const scriptElement = document.createElement('script')
      scriptElement.type = 'text/javascript'

      if (src) {
        scriptElement.src = src
        scriptElement.async = true
      } else {
        scriptElement.text = scriptContent
      }

      document.head.appendChild(scriptElement)
    },

    /**
     * Callback function that is triggered every time the user the consent is changed.
     * Be it initially, or after the user has changed their preferences.
     */
    onPreferenceExpressedOrNotNeededCallback (preference) {
      const purposes = {
        [IubendaCookiePurposes.FUNCTIONALITY]: 'functionality',
        [IubendaCookiePurposes.EXPERIENCE]: 'experience',
        [IubendaCookiePurposes.MEASUREMENT]: 'measurement',
        [IubendaCookiePurposes.MARKETING]: 'marketing'
      }

      for (const [purpose, label] of Object.entries(purposes)) {
        const action = preference.purposes[purpose] ? 'Granted' : 'Denied'
        this.$tracking.event('Consent', action, label)
      }
    }
  }
}
</script>
